<template>
  <div class="background fill-height">

  </div>
</template>

<script>

export default {
  components: {  }
};
</script>

<style lang="scss" scoped>
.background,
.background::before {
  width: 100%;
  height: 100%;
}

.background {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: row nowrap;

  align-items: center;
}

.background::before {
  content: '';
  filter: blur(30%);
  position: absolute;
}

.countdown {
  z-index: 10;

  font-size: 6rem;
  font-weight: 200;
}
</style>
